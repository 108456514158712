<template>
  <div class="comfirm">
    <title-top>实名认证</title-top>
    
    <div class="row align-center bg-white h96">
        <span class="font28 f-333333 fontweight400 ml25 space">真实姓名</span>
        <input class="flex1 font24 f-999999 mr25" v-model="real_name" placeholder="请输入真实姓名">
    </div>
    <div class="line"></div>
    <div class="row align-center bg-white h96">
        <span class="font28 f-333333 fontweight400 ml25 space">身份证号码</span>
        <input class="flex1 font24 f-999999 mr25" v-model="identity_number" placeholder="请输入身份证号码">
        
    </div>
    <div class="row flex-center mb16 position_r">
      <img class="idcard-img" :src="photo || '~images/idcard.png'" alt="">
      <input
        type="file"
        name
        class="file"
        accept="image/gif, image/jpeg, image/jpg, image/png"
        @change="fileChange"
      />
      </div>
    <div class="row align-center ml40 position_r">
        <img class="imgsize32" src="~images/warnicon.png" alt="">
        <span class="font28 f-333333 fontwb ml25">会员实名认证注意事项：</span>
    </div>
    <div class="column text font28 f-333333">
        <p>1. 请上传完整的身份证人像照，身份证号码必须完整清晰；</p>
        <p>2. 填写的真实姓名和身份证号码必须与身份证照内容一致；</p>
        <p>3. 身份证照上的年龄要求符合<span style="color:#EE7C3E;">18周岁至60周岁</span>。</p>
    </div>
    <div class="mt60 row flex-center" @click="onSure">
      <span class="btn-grey" v-if="(res && res.state == 'certified')" style="width:680rpx;">{{res && res.state_zh_cn || '提交'}}</span>
      <span class="btn-red" v-else style="width:680rpx;">{{res && res.state_zh_cn || '提交'}}</span>
    </div>
    <div class="blank"></div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from '@/components/common/title/TitleTop'
import { upload_img, idcard_cert,certification_state } from "network/igou";
export default {
  name: 'Comfirm',
  data(){
    return {
      real_name:'',
      identity_number:'',
      photo:require('images/idcard.png'),
      id:'',
      res:{}
    }
  },
  mounted() {
    certification_state().then(res=>{
      this.res = res.data;
    })
  },
  methods: {
    onSure() {
      if(this.res && this.res.state == 'certified'){return;}
      if(!this.real_name){
        this.$toast.show('真实姓名不能为空');
        return;
      }
      if(!this.identity_number){
        this.$toast.show('身份证不能为空');
        return;
      }
      if(!this.id){
        this.$toast.show('请上传图片');
        return;
      }
      idcard_cert(this.real_name,this.identity_number,this.id).then(res=>{
        this.$toast.show('申请认证已提交.');
      });
    },
    //头像选择
    fileChange(e) {
      var that = this;
      var file = e.target.files[0];//本地路径
      var reader = new FileReader();
      reader.onload = function(e) {
        //that.photo = e.target.result;//base64格式
        upload_img(file,'front_of_id_card')
          .then(res => {
            //图片上传
            that.photo = res.data.url;
            that.id = res.data.id;
          });
      };
      reader.readAsDataURL(file);
    },
  },
  components: {
    TitleTop
  }
}
</script>
<style  scoped>
.file {
  position: absolute;
  top: 0;
  left: 0;
  width: 9.066667rem;
    height: 5.333333rem;
  outline: none;
  opacity: 0;
}
.space{
    width: 2.666667rem;
}
.idcard-img{
    width: 9.066667rem;
    height: 5.333333rem;
}
.text{
    margin:.24rem .56rem 0 1.173333rem;
    line-height: .733333rem;
}

</style>
